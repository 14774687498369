import './testimonials.scss'

export default function Testimonials() {

    const data = [
        {
            id: 1,
            name: "Ahria Nicholas",
            project: 'Amnesia Restored',
            title: "Lead Game Developer",
            img:
              "https://i.ibb.co/HXg3cTg/1635368847539.jpg",
              icon: 'assets/linkedin2.png',
              link: 'https://www.linkedin.com/in/ahrianicholas/',
            desc:
              "You would be lucky to have Hogan on your team. He provides great insight into any problem and is able to deliver fast solutions. Funny and personable, he is someone who gets along with just about everybody. A talented developer that was core to the Amnesia: Restored project's success.",
              shortDesc: `Hogan was someone who was core to the Amnesia: restored project's success.`
          },
        {
          id: 2,
          name: "Andrew Thompson",
          project: 'Amnesia Restored',
          title: "Project Manager",
          img:
            "https://i.ibb.co/t8Dvsd3/1638212437006.jpg",
          icon: "assets/linkedin2.png",
          desc:
            "Hogan is an indispensable member of our game development team, using his skill set to both build the foundation as well as push the Amnesia: Restored project forward. His sharp thinking allows him to quickly visualize potential issues and solve them ahead of time, frequently saving us large chunks of development time. He is a strong addition to any team.",
          featured: true,
          link: 'https://www.linkedin.com/in/andrew-thompson-gamedev/',
          shortDesc:`Hogan is a strong addition to any team.`
        },
        {
          id: 3,
          name: "Ariel Wallace",
          project: 'Amnesia Restored',
          title: "Lead Designer",
          img:
            "https://i.ibb.co/12QnXfV/1634910020235.jpg",
          icon: "assets/linkedin2.png",
          linke: 'https://www.linkedin.com/in/ariel-wallace-00b7b319b/',
          desc:
            "Hogan is a great team player and a hard worker. He helped me with some coding trouble I was having with my website. Hogan was fast, efficient, clear in his explanations and had a very positive and friendly attitude.",
            shortDesc: `Hogan is a great team player and a hard worker. He was fast, efficient, clear in his explanations.`
        },
      ];

    return (
        <div className='testimonials' id='testimonials'>
            <h1>Testimonials</h1>
            <div className="container">
                    {data.map(d=>(
                <div className={d.featured ? 'card featured' : 'card'}>
                  <a href={d.link} rel="noopener noreferrer" target="_blank">
                    <div className="top">
                        <img src="assets/right-arrow2.png" className='left' alt="" />
                        <img className='user' src={d.img} alt="" />
                        <img className='right' src={d.icon} alt="" />
                    </div>
                  </a>
                    <div className="center1">
                        {d.desc}
                    </div>
                    <div className="center2">
                        {d.shortDesc}
                    </div>
                    <div className="bottom">
                        <h3>{d.name}</h3>
                        <h4>{d.title}</h4>
                        <h5>{d.project}</h5>
                    </div>
                </div>))}
            </div>
        </div>
    )
}
