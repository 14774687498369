import PortfolioList from '../portfolioList/PortfolioList'
import './portfolio.scss'
import { useEffect, useState } from 'react';
import {
    featuredPortfolio,
    gamePortfolio,
    photoPortfolio,
    videoPortfolio,
    webPortfolio} from '.././data';

export default function Portfolio() {

    const [selected, setSelected] = useState('featured');
    const [data, setData] = useState([]);

    const list = [
        {
            id: 'featured',
            title: 'Featured',
        },
        {
            id:'gameDeveloper',
            title:'Game Dev'
        },
        {
            id: 'webDeveloper',
            title: 'Web Dev',
        },
        {
            id:'photographer',
            title: 'Photographer',
        },
        {
            id: 'videographer',
            title: 'Videographer',
        }
    ];

    useEffect(()=> {
        switch(selected) {
            case 'featured': 
                setData(featuredPortfolio)
                break;
            case 'gameDeveloper': 
                setData(gamePortfolio)
                break;
            case 'webDeveloper': 
                setData(webPortfolio)
                break;
            case 'photographer': 
                setData(photoPortfolio)
                break;
            case 'videographer': 
                setData(videoPortfolio)
                break;
                default:
                    setData(featuredPortfolio) 
        }

    }, [selected])

    return (
        <div className='portfolio' id='portfolio'>
            <h1>Portfolio</h1>
    <ul>
        {list.map(item=>(
            <PortfolioList 
            title={item.title} 
            active={selected === item.id} 
            setSelected = {setSelected}
            id = {item.id}
            />
        ))}
    </ul>
            <div className="container">
                {data.map(d=> ( 
                  <a href={d.link} rel="noopener noreferrer" target="_blank">
                    <div className="item">
                        <img src={d.img} alt="" />
                        <h3>{d.title}</h3>
                    </div>
                  </a>
                ))}
            </div>
        </div>
    )
}
