import './emailjs.scss';
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';




export default function Emailjs() {

  const [message, setMessage] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('contact_service', 'contact_form', form.current, 'user_QHRjgpCVsX1zqX4FUUHIT')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      setMessage(true);

  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" className='sendButton'/>
      {message && <span>Thank you! I will reply shortly.</span>}
    </form>
  );
};