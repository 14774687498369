export const featuredPortfolio = [
    {
      id: 1,
      title: "Amnesia Restored",
      img:
        "https://i.ibb.co/6RjzTch/Mask-Group-30.png",
      link: 'https://amnesia-restored.com/',
    },
    {
      id: 2,
      title: "g0",
      img:
        "https://i.ibb.co/HPN3357/g0.png",
      link:'https://popsbutnotdad.itch.io/g0'
    },
    {
      id: 3,
      title: "ParaSoul",
      img:
        "https://img.itch.zone/aW1nLzc5OTg2MzEucG5n/original/dXNJp%2B.png",
      link:'https://cmdcstudios.itch.io/parasoul'
    },
    {
      id: 4,
      title: "Vampire Corpse Feast",
      img:
        "https://i.ibb.co/CvNjV4k/vampire-title-logo.png",
      link:'https://starryahri.itch.io/vampire-corpse-feast'
    },
    {
      id: 5,
      title: "Digital Photos",
      img:
        "https://i.ibb.co/wZtbsTf/51688661267-fcae7b1019-w.jpg",
      link: 'https://www.flickr.com/photos/hogancoverdale/',
    },
    {
      id: 6,
      title: "Meet the Main Tank",
      img:
        "https://i.ibb.co/0QFXg3s/mainTank.jpg",
      link: 'https://www.youtube.com/watch?v=GQGt5yjSRA4',
    },
    
  ];
  
  export const gamePortfolio = [
    {
      id: 1,
      title: "Vampire Corpse Feast",
      img:
        "https://i.ibb.co/CvNjV4k/vampire-title-logo.png",
      link:'https://starryahri.itch.io/vampire-corpse-feast'
    },
    {
        id: 2,
        title: "Amnesia Restored",
        img:
          "https://i.ibb.co/6RjzTch/Mask-Group-30.png",
        link: 'https://amnesia-restored.com/',
      },
      {
        id: 3,
        title: "g0",
        img:
          "https://i.ibb.co/HPN3357/g0.png",
        link:'https://popsbutnotdad.itch.io/g0'
      },
      {
        id: 4,
        title: "Orb",
        img:
          "https://cdn.shopify.com/s/files/1/0301/6910/4515/products/orbs-updated-colors_402x.jpg?v=1578431735",
        link: 'https://dtc-wsuv.org/hcoverdale20/final477/'
      },
      {
        id: 5,
        title: "ParaSoul",
        img:
          "https://img.itch.zone/aW1nLzc5OTg2MzEucG5n/original/dXNJp%2B.png",
        link:'https://cmdcstudios.itch.io/parasoul'
      },
      
  ];

  export const webPortfolio = [
    {
      id: 1,
      title: "Portfolio Site",
      img:
        "https://i.ibb.co/Gs1zP5N/portfolio.png",
      link:'https://www.hpc.gg/'
    },
      {
        id: 2,
        title: "Amnesia Restored",
        img:
          "https://i.ibb.co/6RjzTch/Mask-Group-30.png",
        link: 'https://amnesia-restored.com/',
      },
      {
        id: 3,
        title: "Millionaire Game",
        img:
          "https://cdn.akamai.steamstatic.com/steam/apps/1356240/header.jpg?t=1632132433",
        link:'https://who-wants-game.herokuapp.com/'
      },
      
  ];
  
  export const photoPortfolio = [
    {
      id: 1,
      title: "Concert Photos",
      img:
        "https://i.ibb.co/XY1Jw06/MH-15-200x300.jpg",
      link:'http://www.dmndr.com/author/hogan-coverdale/',
    },
    {
      id: 2,
      title: "Film Photos",
      img:
        "https://i.ibb.co/TMMtBCY/200751680-220216059945609-1734376560866088902-n.jpg",
      link: 'https://www.instagram.com/popsbutnotdad/',
    },
    {
      id: 3,
      title: "Digital Photos",
      img:
        "https://i.ibb.co/wZtbsTf/51688661267-fcae7b1019-w.jpg",
      link: 'https://www.flickr.com/photos/hogancoverdale/',
    },
  ];
  
  export const videoPortfolio = [
    {
        id: 1,
        title: "Meet the Main Tank",
        img:
          "https://i.ibb.co/0QFXg3s/mainTank.jpg",
        link: 'https://www.youtube.com/watch?v=GQGt5yjSRA4',
      },
    {
      id: 2,
      title: "My Favorite Game",
      img:
        "https://i.ibb.co/PrjJdb7/DnD.jpg",
      link: 'https://www.youtube.com/watch?v=DykdnidQk3c',
    },
    {
      id: 3,
      title: "Is Water Wet",
      img:
        "https://i.ibb.co/n3mhQj6/is-Water-Wet.jpg",
      link: 'https://www.youtube.com/watch?v=WLLTLgYFLvo',
    },
  ];

// https://ibb.co/sj9QQ71
// https://ibb.co/x65Xf3b
// https://ibb.co/pXRLqzM
// https://ibb.co/vDBsH0K