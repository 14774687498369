import './topbar.scss'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Pdf from '../topbar/Coverdale_Resume_SE-compressed.pdf';

export default function Topbar({menuOpen,setMenuOpen}) {
    return (
        <div className={'topbar ' + (menuOpen && 'active')}>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className='logo'>HPC.gg</a>
                    <div className="itemContainer">
                    <a href="mailto: Hogan@hpc.gg">
                        <MailIcon className='icon'/>
                        <span>Hogan@HPC.gg</span>
                    </a>
                    </div>
                    <div className="itemContainer">
                    
                    <a href="https://www.linkedin.com/in/hogan-coverdale/" rel="noopener noreferrer" target="_blank">
                        <LinkedInIcon className='icon'/>
                            <span>hogan-coverdale</span>
                        </a>
                    </div>

                    <div className="itemContainer">
                    
                    <a href = {Pdf} target = "_blank" rel='noreferrer'>
                        <PictureAsPdfIcon className='icon'/>
                            <span>Resume PDF</span>
                        </a>
                    </div>
                </div>

                <div className="right">
                    <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                        <span className='line1'></span>
                        <span className='line2'></span>
                        <span className='line3'></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
