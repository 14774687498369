import { useState } from 'react';
import './works.scss'

export default function Works() {
    const [currentSlide, setCurrentSlide] = useState(0)

    const data = [
        {
          id: "1",
          icon: 'https://i.ibb.co/tLgZxsG/Pngfind-com-computer-screen-png-294307.png',
          title: "Programming",
          desc:
            "Proficient in game and web development environments such as Unity, Unreal Engine, NodeJS, React, and SCSS/CSS.",
          img:
            "https://i.ibb.co/ns4R2c1/IDE.png",
          linkName: 'GitHub',
          link: 'https://github.com/PopsButNotDad',
          shortDesc: 'Proficient in game and web development.'
          
        },
        {
          id: "2",
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Video_game_controller_icon_designed_by_Maico_Amorim.svg/1200px-Video_game_controller_icon_designed_by_Maico_Amorim.svg.png',
          title: "Game Design",
          desc:
            "Able to take white board ideas and develop them into fun gameplay mechanics, levels, and story elements for both video and tabletop games alike.",
          img:
            "https://i.ibb.co/m8Fk5Lm/unity.png",
          linkName: 'Itch.io',
          link:'https://popsbutnotdad.itch.io/',
          shortDesc: 'Passionate about creating fun gameplay experiences.'
        },
        {
          id: "3",
          icon: 'https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/camera-512.png',
          title: "Photography",
          desc:
            "I have almost a decade of professional photography under my belt and recently have gotten into shooting 35mm film.",
          img:
            "https://i.ibb.co/Hd0KLkV/lrc2.png",
          linkName: 'Flickr',
          link: 'https://www.flickr.com/photos/hogancoverdale/',
          featured: true,
        },
        {
          id: "4",
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Video_camera_icon.svg/1200px-Video_camera_icon.svg.png',
          title: "Videography",
          desc:
            "With my love of being behind a camera combined with compiling to be creative it's only natural I enjoy making videos.",
          img:
            "https://i.ibb.co/MC5CCCZ/premiere2.png",
          linkName: 'Youtube',
          link:'https://www.youtube.com/channel/UCErltT_kgPGw9LrhFCicjBg',
          featured: true,
        },
        {
          id: "5",
          icon: 'https://i.ibb.co/z8byj3w/Pin-Clipart-com-park-clip-art-black-813054.png',
          title: "Resume",
          desc:
            "A detailed history of my professional experience can be found below. For further inquiries please contact me via the contact page or my email Hogan@HPC.gg",
          img:
            "https://i.ibb.co/GJnKfR3/Coverdale-Resume-SD.png",
          linkName: 'Resume',
          link:'https://i.ibb.co/GJnKfR3/Coverdale-Resume-SD.png',
          shortDesc: 'To see a more detailed history click the link below'
        },
        
      ];

      const handleClick = (way) => {
          way === 'left' 
          ? setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 2) 
          : setCurrentSlide(currentSlide < data.length -1 ? currentSlide + 1 : 0)
      }

    return (
        <div className='works' id='works'>
            <div className="slider" style={{ transform: `translateX(-${currentSlide *100}vw)` }}>
              {data.map(d=> (
                <div className={d.featured ? 'container featured' : 'container'}>
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <div className="imgContainer">
                                    <img src={d.icon} alt="" />
                                </div>
                                <h2>{d.title}</h2>
                              <div className="longDescription">
                                <p>
                                    {d.desc}
                                </p>
                                </div>
                              <div className="shortDescription">
                                <p>
                                  {d.shortDesc}
                                </p>
                    
                              </div>
                                <a href={d.link} rel="noopener noreferrer" target="_blank">
                                <span>{d.linkName}</span>
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <img src={d.img} alt="" />
                        </div>
                    </div>
                </div>))}
            </div>
            <img src="assets/arrow.png" className='arrow left' alt="" onClick={()=>handleClick('left')}/>
            <img src="assets/arrow.png" className='arrow right' alt="" onClick={()=>handleClick()}/>
        </div>
    );
}
