import './contact.scss'
import ContactUs from '../emailJS/Emailjs'

export default function Contact() {
    
    return (
        <div className='contact' id='contact'>
            <div className="left">
                <img src="assets/shake.png" alt="" />
            </div>
            <div className="right">
                <h2>Get in touch.</h2>
                <ContactUs/>
            </div>
        </div>
    )
}
